import { ReactComponent as EyeClose } from "@assets/icons/eyeClose.svg";
import { ReactComponent as PinGreenActive } from "@assets/icons/pin-green-active.svg";
import { ReactComponent as Whatsapp } from "@assets/icons/whatsapp.svg";
import { ReactComponent as Search } from "@assets/icons/search.svg";
import { ReactComponent as SearchActive } from "@assets/icons/search-active.svg";
import { ReactComponent as ChevronRight } from "@assets/icons/chevron-right.svg";
import { ReactComponent as EyeOpen } from "@assets/icons/eyeOpen.svg";
import { ReactComponent as Close } from "@assets/icons/close.svg";
import { ReactComponent as LogoGreen } from "@assets/icons/inawo-logo-3.svg";
import { ReactComponent as LogoWhite } from "@assets/icons/inawo-logo-2.svg";
import { ReactComponent as Calendar } from "@assets/icons/calendar.svg";
import { ReactComponent as CalendarActive } from "@assets/icons/calendar-active.svg";
import { ReactComponent as Home } from "@assets/icons/home.svg";
import { ReactComponent as HomeActive } from "@assets/icons/home-active.svg";
import { ReactComponent as Interest } from "@assets/icons/interest.svg";
import { ReactComponent as InterestActive } from "@assets/icons/interest-active.svg";
import { ReactComponent as Bell } from "@assets/icons/bell.svg";
import { ReactComponent as Bookmark } from "@assets/icons/bookmark.svg";
import { ReactComponent as ArrowUp } from "@assets/icons/arrow-up.svg";
import { ReactComponent as ArrowDown } from "@assets/icons/arrow-down.svg";
import { ReactComponent as Rocket } from "@assets/icons/rocket.svg";
import { ReactComponent as Burger } from "@assets/icons/burger.svg";
import { ReactComponent as ArrowLeft } from "@assets/icons/arrow-left.svg";
import { ReactComponent as PinGrey } from "@assets/icons/pin-grey.svg";
import { ReactComponent as Vendors } from "@assets/icons/vendors.svg";
import { ReactComponent as VendorsActive } from "@assets/icons/vendors-active.svg";
import { ReactComponent as PinGreen } from "@assets/icons/pin-green.svg";
import { ReactComponent as ChevronUp } from "@assets/icons/chevron-up.svg";
import { ReactComponent as More } from "@assets/icons/more.svg";
import { ReactComponent as MoreActive } from "@assets/icons/more-active.svg";
import { ReactComponent as Insight } from "@assets/icons/insight.svg";
import { ReactComponent as InsightActive } from "@assets/icons/insight-active.svg";
import { ReactComponent as Service } from "@assets/icons/service.svg";
import { ReactComponent as ServiceActive } from "@assets/icons/service-active.svg";
import { ReactComponent as Date } from "@assets/icons/date.svg";
import { ReactComponent as Delete } from "@assets/icons/delete.svg";
import { ReactComponent as Pinned } from "@assets/icons/pinned.svg";
import { ReactComponent as NotPinned } from "@assets/icons/not-pinned.svg";
import { ReactComponent as Sample } from "@assets/icons/sample.svg";

export type IconType =
  | "close"
  | "sample"
  | "eyeOpen"
  | "eyeClose"
  | "chevronUp"
  | "pinned"
  | "notPinned"
  | "date"
  | "delete"
  | "more"
  | "moreActive"
  | "insight"
  | "insightActive"
  | "service"
  | "serviceActive"
  | "search"
  | "searchActive"
  | "logoGreen"
  | "pinGreenActive"
  | "whatsapp"
  | "vendors"
  | "pinGreen"
  | "chevronRight"
  | "vendorsActive"
  | "arrowLeft"
  | "logoWhite"
  | "calendar"
  | "calendarActive"
  | "home"
  | "homeActive"
  | "interest"
  | "interestActive"
  | "arrowUp"
  | "arrowDown"
  | "bell"
  | "burger"
  | "pinGrey"
  | "bookmark"
  | "rocket";

export const icons: Record<IconType, JSX.Element> = {
  close: <Close />,
  eyeClose: <EyeClose />,
  search: <Search />,
  sample: <Sample />,
  delete: <Delete />,
  searchActive: <SearchActive />,
  eyeOpen: <EyeOpen />,
  logoGreen: <LogoGreen />,
  chevronRight: <ChevronRight />,
  logoWhite: <LogoWhite />,
  calendar: <Calendar />,
  pinned: <Pinned />,
  notPinned: <NotPinned />,
  calendarActive: <CalendarActive />,
  pinGreenActive: <PinGreenActive />,
  whatsapp: <Whatsapp />,
  home: <Home />,
  pinGreen: <PinGreen />,
  homeActive: <HomeActive />,
  pinGrey: <PinGrey />,
  interest: <Interest />,
  interestActive: <InterestActive />,
  arrowLeft: <ArrowLeft />,
  bell: <Bell />,
  burger: <Burger />,
  bookmark: <Bookmark />,
  arrowDown: <ArrowDown />,
  date: <Date />,
  arrowUp: <ArrowUp />,
  more: <More />,
  moreActive: <MoreActive />,
  service: <Service />,
  serviceActive: <ServiceActive />,
  insight: <Insight />,
  insightActive: <InsightActive />,
  chevronUp: <ChevronUp />,
  rocket: <Rocket />,
  vendors: <Vendors />,
  vendorsActive: <VendorsActive />,
};
